import type { AppProps } from 'next/app'
import { appWithTranslation } from 'next-i18next'
import React, { useEffect } from 'react'
import { useRouter } from 'next/router'
import zhCN from 'antd/lib/locale/zh_CN'
import { ConfigProvider } from 'antd'

import 'antd/dist/antd.css'
import '../styles/globals.css'

function MyApp ({ Component, pageProps }: AppProps) {
  const router = useRouter()

  useEffect(() => {
    router.events.on('routeChangeComplete', (url) => {
      try {
        // eslint-disable-next-line no-undef
        (window as any)._hmt.push(['_trackPageview', url])
      } catch (e) { }
      try {
        // eslint-disable-next-line no-undef
        (window as any)._czc.push(['_trackPageview', url])
      } catch (e) { }

      try {
        const e = /([http|https]:\/\/[a-zA-Z0-9\_\.]+\.baidu\.com)/gi
        const r = window.location.href
        const t = document.referrer
        if (!e.test(r)) {
          let o = 'https://sp0.baidu.com/9_Q4simg2RQJ8t7jm9iCKT-xh_/s.gif'
          // eslint-disable-next-line no-unused-expressions
          t ? ((o += '?r=' + encodeURIComponent(document.referrer)), r && (o += '&l=' + r)) : r && (o += '?l=' + r)
          const i = new Image()
          i.src = o
        }
      } catch (e) { }
    })
  }, [])
  return (
    <ConfigProvider locale={zhCN}>
      <Component {...pageProps} />
    </ConfigProvider>
  )
}
export default appWithTranslation(MyApp)
